import React from 'react'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'

interface ContactTypeItemProps {
    icon: React.ReactNode,
    title: React.ReactNode
    subtitle: string
    isHighlighted?: boolean
}

export default function ContactTypeItem({ icon, title, subtitle, isHighlighted }: ContactTypeItemProps) {

  return (
    <Stack
      p={{
        xs: 3,
        md: 5
      }}
      sx={{
        position: 'relative',
        backgroundImage: 'url("https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/Wood-Textures-17-1.png")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        backgroundPosition: isHighlighted ? 'center left': 'center right',
        border: '1px solid #695743',
        color: isHighlighted? '#fff': '#000',
        '&:before': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          content: '""',
          backgroundColor: isHighlighted ? '#3c352e' : '#c3a995',
          opacity: 0.85
        },
        '&  h2': {
          fontSize: '1.7em',
          fontWeight: 600,
          letterSpacing: '1.4px'
        }
      }}
      width={{
        xs: '100%',
        md: '50%'
      }}>
      <Stack
        alignItems={'center'}
        zIndex={1}
        gap={{
          xs: 1,
          md: 3
        }}
      >
        {icon}
        <Typography
          level={'h2'}
          sx={{
            '& a': {
              color: isHighlighted ? '#d5bda2': '#000',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }
          }}>
          {title}
        </Typography>
        <Typography
          textAlign={'center'}
          sx={{
            color: isHighlighted ? '#bababa': '#000'
          }}
        >
          {subtitle}
        </Typography>
      </Stack>
    </Stack>
  )
}
