import React from 'react'
import Container from '@mui/joy/Container'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

import OurTestimonialsItemLeft from './our-testimonials-item-left'
import OurTestimonialsItemRight from './our-testimonials-item-right'

const StyledImage = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '100%',
  backgroundColor: '#ede0d4',
  '&:before': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    content: '""',
    backgroundImage: 'url("https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/Wood-Textures-17-1.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    backgroundPosition: 'center center',
    opacity: 0.03
  }
})

const StyledHeading = styled(Typography)({
  color: '#27221e',
  fontWeight: 800,
  lineHeight: 1,
  letterSpacing: '0px',
  '@media screen and (min-width:1025px)': {
    fontSize: '5em'
  }
})

export default function OurTestimonials() {

  const itemsLeft = [{
    id: 1,
    client: {
      avatar: 'https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/Testi-1.jpg',
      name: 'Sebastian',
      role: 'Client'
    },
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.'
  }/*,{
    id: 2,
    client: {
      avatar: 'https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/Testi-1.jpg',
      name: 'Sebastian',
      role: 'Client'
    },
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.'
  }*/]

  const itemsRight = [{
    id: 1,
    client: {
      avatar: 'https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/Testi-1.jpg',
      name: 'Sebastian',
      role: 'Client'
    },
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.'
  }/*,{
    id: 2,
    client: {
      avatar: 'https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/Testi-1.jpg',
      name: 'Sebastian',
      role: 'Client'
    },
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.'
  }*/]

  return (
    <Stack
      py={{
        xs: 3,
        md: 15
      }}
      sx={{
        backgroundColor: '#3c352e',
        width: '100%',
        zIndex: 1,
        position: 'relative'
      }}>
      <StyledImage />

      <Container
        maxWidth={'lg'}
        sx={{
          zIndex: 2
        }}
      >
        <Stack
          alignContent={'space-evenly'}
          alignItems={'center'}
          gap={3}
          width={'100%'}
          direction={{
            xs: 'column',
            md: 'row'
          }}>
          <Stack
            pb={{
              xs: 0,
              md: 5
            }}>
            <StyledHeading
              level={'h2'}>
                OUR TESTIMONIALS
            </StyledHeading>
          </Stack>
        </Stack>
        <Stack
          gap={2.5}
          direction={{
            xs: 'column',
            md: 'row'
          }}
          pt={{
            xs: 3,
            md: 5
          }}>
          <OurTestimonialsItemLeft items={itemsLeft} />
          <OurTestimonialsItemRight items={itemsRight} />
        </Stack>
      </Container>

    </Stack>
  )
}
