import React from 'react'

import Banner from 'components/banner'
import ContactType from 'components/contact-type'

export default function ContactPage() {

  return (
    <>
      <Banner
        title={'CONTACT US'}
        text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper'
                + ' mattis, pulvinar dapibus leo.'}
      />
      <ContactType />
    </>
  )
}
