import React from 'react'
import Container from '@mui/joy/Container'
import Stack from '@mui/joy/Stack'
import { Mail,Phone } from 'lucide-react'

import ContactTypeItem from './contact-type-item'

export default function ContactType() {

  const items =[{
    id: 1,
    icon: <Phone
      height={65}
      strokeWidth={1}
      width={65}
    />,
    title: <a href={'tel:416 6979771'}>416 6979771</a>,
    subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  }, {
    id: 2,
    icon: <Mail
      height={65}
      strokeWidth={1}
      width={65} />,
    title: <a href={'mailto:alen@alwoodflooring.ca'}>alen@alwoodflooring.ca</a>,
    subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  }]

  return (
    <Stack
      py={{
        xs: 7,
        md: 15
      }}
      sx={{
        backgroundColor: '#27221e',
        width: '100%',
        zIndex: 1
      }}>

      <Container
        maxWidth={'lg'}
        sx={{
          zIndex: 2
        }}
      >
        <Stack
          gap={3}
          direction={{
            xs: 'column',
            sm: 'row'
          }}>
          {items.map((item, idx) => (
            <ContactTypeItem
              key={item.id}
              {...item}
              isHighlighted={idx %2 === 0}
            />
          ))}
        </Stack>
      </Container>
    </Stack>
  )
}
