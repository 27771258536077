import React from 'react'

import AboutUs from 'components/about-us'
import HowWeWork from 'components/how-we-work'
import OurTestimonials from 'components/our-testimonials'
import QualityFlooring from 'components/quality-flooring'
import StickyMain from 'components/sticky-main'
import WhyChooseUs from 'components/why-choose-us'

export default function HomePage() {

  return (
    <>
      <StickyMain />
      <AboutUs />
      <WhyChooseUs />
      <QualityFlooring />
      <HowWeWork />
      <OurTestimonials />
    </>
  )
}
