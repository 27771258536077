import React from 'react'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'
import { HardHat, Layers3, ThumbsUp } from 'lucide-react'

import WhyChooseUsItem from './why-choose-us-item'

const StyledHeading = styled(Typography)({
  color: '#d5bda2',
  fontWeight: 800,
  lineHeight: 1,
  letterSpacing: '0px',
  '@media screen and (min-width:1025px)': {
    fontSize: '5em'
  }
})
export default function WhyChooseUs() {

  const items = [{
    icon: <HardHat
      height={50}
      strokeWidth={1}
      width={50}
    />,
    title: 'Expert Craftsmanship',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis pulvinar.'
  }, {
    icon: <Layers3
      height={50}
      strokeWidth={1}
      width={50}/>,
    title: 'Innovative Designs',
    text: 'Experience the power of innovative design with Alwood Flooring. '
        + 'Let us inspire you to create a one-of-a-kind flooring masterpiece that will truly elevate your home or business.'
  },{
    icon: <ThumbsUp
      height={50}
      strokeWidth={1}
      width={50}
    />,
    title: 'Best Material',
    text: 'Our team of experts is dedicated to helping you choose the perfect material for your project, '
        + 'taking into account factors such as style, budget, and functionality. Whether you\'re seeking the'
        + ' warmth of natural wood, the versatility of luxury vinyl or laminate, we have the expertise to '
        + 'guide you towards the best material for your needs.'
  }]

  return (
    <Stack
      direction={{
        xs: 'column',
        md: 'row'
      }}
      sx={{
        backgroundColor: '#27221e',
        width: '100%',
        zIndex: 1
      }}>

      <Stack
        minWidth={{
          xs: '100%',
          md: '50%'
        }}
        sx={{
          backgroundImage: 'url("https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/hardhat-2023-11-27-05-07-46-utc-1.jpg")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
      <Stack
        gap={3}
        alignItems={{
          xs: 'center',
          md: 'flex-start'
        }}
        pl={{
          xs: 3,
          md: 10
        }}
        pr={{
          xs: 3,
          ms: 18
        }}
        py={{
          xs: 7,
          md: 14
        }}
      >
        <StyledHeading level={'h2'}>
            Why Choose Us
        </StyledHeading>

        {items.map((item, idx) => (
          <WhyChooseUsItem
            key={item.title}
            {...item}
            highlight={idx % 2 !== 0}
          />
        ))}
      </Stack>
    </Stack>
  )
}
