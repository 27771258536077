import * as React from 'react'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Container from '@mui/joy/Container'
import Drawer from '@mui/joy/Drawer'
import Link from '@mui/joy/Link'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import { Menu } from 'lucide-react'

import Logo from 'components/logo'

const StyledLink = styled(Link)({
  color: '#D5BDA2',
  '&:hover': {
    color: '#fff'
  }
})

const StyledButton = styled(Button)({
  borderRadius: 0,
  color: '#D5BDA2',
  backgroundColor: '#3C352E',
  border: '1px solid #695743',
  // width: 170,
  height: 47,
  '&:hover, &:active, &:focus': {
    color: '#27221e',
    backgroundColor: '#d5bda2'
  },
  '@media screen and (max-width:599px)': {
    height: 42,
    padding: 8

  }
})

export default function Header() {
  const [drawerOpen, toggleDrawer] = React.useState(false)

  return (
    <Box
      sx={{
        top: 0,
        zIndex: 99,
        backgroundColor: 'rgba(60, 53, 46, 0.8);',
        width: '100%'
      }}>
      <Container maxWidth={'lg'}>
        <Stack
          direction={'row'}
          height={'88px'}
          justifyContent={'space-between'}
          py={1}>
          <Stack
            alignItems={'center'}
            direction={'row'}
            gap={1}
            justifyContent={'center'}
            width={{
              xs: '250px',
              sm: '350px'
            }}
          >
            <Link href={'/'}>
              <Logo />
            </Link>
          </Stack>

          <Stack
            alignItems={'center'}
            direction={'row'}
            display={{
              xs: 'flex',
              md: 'none'
            }}>
            <StyledButton
              onClick={() => toggleDrawer(true)}
            >
              <Menu />
            </StyledButton>
          </Stack>

          <Stack
            alignItems={'center'}
            direction={'row'}
            display={{
              xs: 'none',
              md: 'flex'
            }}
            gap={{
              lg: 6,
              xs: 3
            }}>
            <StyledLink
              href={'/'}>
              HOME
            </StyledLink>
            <StyledLink
              href={'/about'}>
              ABOUT
            </StyledLink>
            <StyledLink
              href={'/services'}>
              SERVICES
            </StyledLink>

            <StyledButton
              // @ts-ignore
              component={Link}
              href={'/contact'}
            >
              CONTACT US
            </StyledButton>
          </Stack>
        </Stack>
      </Container>
      <Drawer
        anchor={'right'}
        onClose={() => toggleDrawer(false)}
        open={drawerOpen}

      >
        <List
          sx={{
            backgroundColor: 'rgba(60, 53, 46, 0.8);',
            '& > li': {
              '&:first-of-type': {
                marginTop: '16px',
                marginBottom: '30px'
              },
              marginBottom: '16px',
              display: 'flex',
              justifyContent: 'center'
            }
          }}
        >
          <ListItem >
            <Logo />
          </ListItem>
          <ListItem >
            <StyledLink
              href={'/'}>
                  HOME
            </StyledLink>
          </ListItem>
          <ListItem >
            <StyledLink
              href={'/about'}>
                  ABOUT
            </StyledLink>
          </ListItem>
          <ListItem>
            <StyledLink
              href={'/services'}>
                    SERVICES
            </StyledLink>
          </ListItem>
          <ListItem >
            <StyledButton
              // @ts-ignore
              component={Link}
              href={'/contact'}
            >
                  CONTACT US
            </StyledButton>
          </ListItem>

        </List>
      </Drawer>
    </Box>
  )
}
