import React from 'react'
import Container from '@mui/joy/Container'
import Divider from '@mui/joy/Divider'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

import QualityFlooringItem from './quality-flooring-item'

const StyledImage = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '60%',
  backgroundColor: '#ede0d4',
  '&:before': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    content: '""',
    backgroundImage: 'url("https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/Wood-Textures-17-1.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    backgroundPosition: 'center center',
    opacity: 0.03
  }
})

const StyledHeading = styled(Typography)({
  color: '#27221e',
  fontWeight: 800,
  lineHeight: 1,
  letterSpacing: '0px',
  '@media screen and (min-width:1025px)': {
    fontSize: '5em'
  }
})

export default function QualityFlooring() {

  const items = [{
    img: 'https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/Image-1.jpg',
    title: 'FLOORING INSTALLATION',
    subtitle: 'We offer a wide range of flooring options, including Hardwood (glue down and nail down),'
        + ' Engineered hardwood, Laminate, Luxury vinyl planks (LVP), Baseboards, Quarter round and Shoe moulding.'
  }, {
    img: 'https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/Image-2.jpg',
    title: 'RENOVATIONS',
    subtitle: 'Our team can help you renovate existing floors, providing all the necessary services from removal'
        + ' to installation of new floors.'
  }, {
    img: 'https://kitpro.site/floorey/wp-content/uploads/sites/224/2024/02/Image-3.jpg',
    title: 'COMMERCIAL PROJECTS',
    subtitle: 'We specialize in flooring solutions for commercial spaces such as offices, shops, and restaurants.'
        + ' We provide fast and efficient service tailored to your schedule.'
  }]

  return (
    <Stack
      sx={{
        backgroundColor: '#3c352e',
        width: '100%',
        zIndex: 1,
        position: 'relative'
      }}>
      <StyledImage />

      <Container
        maxWidth={'lg'}
        sx={{
          zIndex: 2
        }}
      >
        <Stack
          pb={{
            xs: 5,
            md: 10
          }}>
          <Divider />
        </Stack>
        <Stack
          alignContent={'space-evenly'}
          alignItems={'center'}
          gap={3}
          width={'100%'}
          direction={{
            xs: 'column',
            md: 'row'
          }}>
          <Stack
            alignItems={{
              xs: 'center',
              sm: 'flex-start'
            }}
            pb={{
              md: 5
            }}>
            <StyledHeading
              level={'h2'}>
              QUALITY FLOORING
            </StyledHeading>
            <Stack
              width={'70%'}>
              <Typography
                sx={{
                  color: '#7A7A7A'
                }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus,
              luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          gap={2.5}
          direction={{
            xs: 'column',
            sm: 'row'
          }}
          pt={{
            xs: 2,
            md: 5
          }}>
          {items.map((item) => (<QualityFlooringItem
            key={item.title}
            {...item} />
          ))}
        </Stack>
      </Container>

    </Stack>
  )
}
