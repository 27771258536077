import React from 'react'
import AboutPage from 'pages/about'
import ContactPage from 'pages/contact'
import HomePage from 'pages/home'
import ServicesPage from 'pages/services'

const rootRouter = [
  {
    path: '',
    element: <HomePage />
  },
  {
    path: 'about',
    element: <AboutPage />
  },
  {
    path: 'services',
    element: <ServicesPage />
  },
  {
    path: 'contact',
    element: <ContactPage />
  }
]

export default rootRouter
